<template>
	<div class="nav">
		<ul>
			<li>
				<router-link :to="{ name: 'Dashboard' }">
					<svg height="30px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
						<path d="m197.332031 170.667969h-160c-20.585937 0-37.332031-16.746094-37.332031-37.335938v-96c0-20.585937 16.746094-37.332031 37.332031-37.332031h160c20.589844 0 37.335938 16.746094 37.335938 37.332031v96c0 20.589844-16.746094 37.335938-37.335938 37.335938zm-160-138.667969c-2.941406 0-5.332031 2.390625-5.332031 5.332031v96c0 2.945313 2.390625 5.335938 5.332031 5.335938h160c2.945313 0 5.335938-2.390625 5.335938-5.335938v-96c0-2.941406-2.390625-5.332031-5.335938-5.332031zm0 0" />
						<path d="m197.332031 512h-160c-20.585937 0-37.332031-16.746094-37.332031-37.332031v-224c0-20.589844 16.746094-37.335938 37.332031-37.335938h160c20.589844 0 37.335938 16.746094 37.335938 37.335938v224c0 20.585937-16.746094 37.332031-37.335938 37.332031zm-160-266.667969c-2.941406 0-5.332031 2.390625-5.332031 5.335938v224c0 2.941406 2.390625 5.332031 5.332031 5.332031h160c2.945313 0 5.335938-2.390625 5.335938-5.332031v-224c0-2.945313-2.390625-5.335938-5.335938-5.335938zm0 0" />
						<path d="m474.667969 512h-160c-20.589844 0-37.335938-16.746094-37.335938-37.332031v-96c0-20.589844 16.746094-37.335938 37.335938-37.335938h160c20.585937 0 37.332031 16.746094 37.332031 37.335938v96c0 20.585937-16.746094 37.332031-37.332031 37.332031zm-160-138.667969c-2.945313 0-5.335938 2.390625-5.335938 5.335938v96c0 2.941406 2.390625 5.332031 5.335938 5.332031h160c2.941406 0 5.332031-2.390625 5.332031-5.332031v-96c0-2.945313-2.390625-5.335938-5.332031-5.335938zm0 0" />
						<path d="m474.667969 298.667969h-160c-20.589844 0-37.335938-16.746094-37.335938-37.335938v-224c0-20.585937 16.746094-37.332031 37.335938-37.332031h160c20.585937 0 37.332031 16.746094 37.332031 37.332031v224c0 20.589844-16.746094 37.335938-37.332031 37.335938zm-160-266.667969c-2.945313 0-5.335938 2.390625-5.335938 5.332031v224c0 2.945313 2.390625 5.335938 5.335938 5.335938h160c2.941406 0 5.332031-2.390625 5.332031-5.335938v-224c0-2.941406-2.390625-5.332031-5.332031-5.332031zm0 0" />
					</svg>
					Dash
				</router-link>
			</li>
			<li v-if="websites">
				<router-link :to="{ name: 'Websites' }">
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 30 30" style="overflow:visible;enable-background:new 0 0 30 30;" xml:space="preserve">
						<path d="M25.6,4.4c-5.9-5.9-15.4-5.9-21.2,0s-5.9,15.4,0,21.2s15.4,5.9,21.2,0S31.5,10.3,25.6,4.4L25.6,4.4zM5.6,5.6c1.3-1.3,2.8-2.3,4.6-3C9.7,3.3,9.3,3.9,8.9,4.6C8.5,5.5,8.1,6.4,7.8,7.3C6.7,7.2,5.7,7,4.7,6.7C5,6.3,5.3,6,5.6,5.6zM3.6,8.2C4.8,8.6,6.1,8.8,7.3,9c-0.4,1.7-0.6,3.4-0.6,5.1H1.8C1.9,12,2.6,10,3.6,8.2z M3.5,21.7c-1-1.8-1.6-3.7-1.8-5.8h4.9c0.1,1.7,0.3,3.3,0.6,4.9C6,21,4.8,21.3,3.5,21.7L3.5,21.7z M5.6,24.4c-0.4-0.4-0.7-0.8-1-1.2c1-0.3,2.1-0.5,3.2-0.7c0.3,1,0.7,1.9,1.2,2.8c0.4,0.7,0.8,1.4,1.4,2.1C8.6,26.7,7,25.7,5.6,24.4L5.6,24.4z M14.1,28c-1.3-0.4-2.6-1.6-3.6-3.5c-0.4-0.7-0.7-1.4-0.9-2.2c1.4-0.2,3-0.3,4.6-0.3V28z M14.1,20.2c-1.7,0-3.4,0.1-5.1,0.3c-0.4-1.5-0.6-3.1-0.6-4.7h5.7L14.1,20.2L14.1,20.2z M14.1,14.1H8.4c0-1.6,0.3-3.2,0.6-4.8c1.7,0.2,3.4,0.3,5.1,0.4L14.1,14.1z M14.1,7.9c-1.6,0-3.1-0.1-4.6-0.3c0.3-0.8,0.6-1.5,0.9-2.2c1-1.9,2.3-3.2,3.6-3.5V7.9L14.1,7.9z M26.4,8.3c1.1,1.8,1.7,3.8,1.8,5.8h-4.9c0-1.7-0.3-3.4-0.6-5C24,8.9,25.2,8.6,26.4,8.3L26.4,8.3z M24.4,5.6c0.4,0.4,0.7,0.7,1,1.1c-1,0.3-2.1,0.5-3.1,0.7c-0.3-1-0.7-1.9-1.2-2.8c-0.4-0.7-0.8-1.3-1.3-1.9C21.5,3.3,23.1,4.3,24.4,5.6z M15.9,15.9h5.7c-0.1,1.6-0.3,3.2-0.6,4.7c-1.7-0.2-3.4-0.3-5.1-0.4L15.9,15.9z M15.9,14.1V9.7c1.7,0,3.4-0.1,5.1-0.3c0.4,1.6,0.6,3.2,0.6,4.8L15.9,14.1L15.9,14.1z M15.9,1.9L15.9,1.9c1.3,0.4,2.6,1.6,3.6,3.5c0.4,0.7,0.7,1.5,1,2.2c-1.5,0.2-3,0.3-4.6,0.3L15.9,1.9z M15.9,28v-6c1.6,0,3.1,0.1,4.6,0.3c-0.3,0.7-0.6,1.5-0.9,2.2C18.5,26.4,17.2,27.6,15.9,28L15.9,28z M24.4,24.4c-1.3,1.3-2.9,2.4-4.7,3c0.5-0.6,1-1.3,1.4-2.1c0.5-0.9,0.8-1.8,1.2-2.7c1.1,0.2,2.1,0.4,3.1,0.7C25,23.6,24.7,24,24.4,24.4z M26.4,21.7c-1.2-0.4-2.5-0.7-3.7-0.9c0.4-1.6,0.6-3.3,0.6-5h4.9C28.1,17.9,27.5,20,26.4,21.7L26.4,21.7z"/>
					</svg>
					Websites
				</router-link>
			</li>
			<li v-if="pages">
				<router-link :to="{ name: 'Pages' }">
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="28px" viewBox="0 0 30.5 28" style="overflow:visible;enable-background:new 0 0 30.5 28;" xml:space="preserve">
						<path d="M18.6,0H2.8C1.3,0,0,1.2,0,2.8c0,20.3-0.1,19,0.3,19.4c5.9,5.9,5.6,5.6,5.8,5.7C6.4,28.1,5.5,28,18.6,28c1.5,0,2.8-1.2,2.8-2.8V2.8C21.4,1.2,20.2,0,18.6,0z M5.5,24.6l-2.1-2.1h2.1L5.5,24.6z M19.4,25.2c0,0.4-0.4,0.8-0.8,0.8H7.5v-4.5c0-0.5-0.4-1-1-1c0,0,0,0,0,0H2V2.8C2,2.3,2.3,2,2.8,2h15.8c0.4,0,0.8,0.4,0.8,0.8L19.4,25.2z M26,2.8v22.4c0,1.5-1.2,2.8-2.8,2.8c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1c0,0,0,0,0,0c0.4,0,0.8-0.4,0.8-0.8V2.8C24,2.3,23.6,2,23.2,2c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1C24.7,0,26,1.2,26,2.8z M30.5,2.8v22.4c0,1.5-1.2,2.8-2.8,2.8c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1c0,0,0,0,0,0c0.4,0,0.8-0.4,0.8-0.8V2.8c0-0.4-0.4-0.8-0.8-0.8c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1l0,0C29.3,0,30.5,1.2,30.5,2.8z"/>
					</svg>
					Pages
				</router-link>
			</li>
			<li v-if="forms">
				<router-link :to="{ name: 'Forms' }">
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24.8px" height="30px" viewBox="0 0 24.8 30" style="overflow:visible;enable-background:new 0 0 24.8 30;" xml:space="preserve">
						<path d="M15.1,0H1.8C0.9,0,0.1,0.7,0,1.6v26.7c0.1,1,0.9,1.7,1.8,1.6H23c1,0.1,1.8-0.7,1.8-1.6V8.1c0-0.4-0.2-0.9-0.5-1.2l-7.9-6.5C16.1,0.2,15.6,0,15.1,0z M22.5,27.9H2.3V2.1h11v5.5c0.1,1.3,1.2,2.3,2.5,2.2h6.8L22.5,27.9zM15.7,7.7c-0.1,0-0.2-0.1-0.2-0.2V3.2l5.3,4.5L15.7,7.7L15.7,7.7z M5.6,13.4c-0.7,0-1.4,0.5-1.4,1.3C4.3,15.4,5,16,5.8,15.9c0.7-0.1,1.2-0.6,1.3-1.3C7,13.9,6.4,13.3,5.6,13.4L5.6,13.4z M8.2,13.6h12.2v2.1H8.2L8.2,13.6z M5.6,17.5c-0.7,0.1-1.2,0.7-1.1,1.4c0.1,0.7,0.7,1.2,1.4,1.1c0.7-0.1,1.1-0.6,1.1-1.3C7,18,6.4,17.4,5.6,17.5L5.6,17.5z M8.2,17.7h12.2v2.1H8.2L8.2,17.7z M5.6,21.6c-0.7,0.1-1.2,0.7-1.1,1.4c0.1,0.7,0.7,1.2,1.4,1.1c0.7-0.1,1.1-0.6,1.1-1.3C7,22.1,6.4,21.5,5.6,21.6L5.6,21.6z M8.2,21.8h12.2v2H8.2L8.2,21.8z"/>
					</svg>
					Forms
				</router-link>
			</li>
			<li class="users" v-if="users">
				<router-link :to="{ name: 'Users' }">
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="23.7px" height="30px" viewBox="0 0 23.7 30" style="overflow:visible;enable-background:new 0 0 23.7 30;" xml:space="preserve">
						<g>
							<path d="M18.2,14.5c-0.4,0.5-0.9,1-1.4,1.4c3.1,1.8,5,5.1,5,8.6c0,2-4.4,3.6-9.9,3.6s-9.9-1.6-9.9-3.6c0-3.6,1.9-6.9,5-8.6c-0.5-0.4-1-0.9-1.4-1.4c-3.4,2.2-5.5,6-5.5,10c0,1.3,0.7,3.1,4,4.3c2.5,0.8,5.2,1.2,7.8,1.2c2.7,0,5.3-0.4,7.8-1.2c3.3-1.2,4-3,4-4.3C23.7,20.5,21.6,16.7,18.2,14.5z"/>
							<path d="M11.9,15.8c3.7,0,6.6-3.6,6.6-7.9S15.6,0,11.9,0S5.2,3.6,5.2,7.9S8.1,15.8,11.9,15.8z M11.9,1.9c2.6,0,4.7,2.7,4.7,6s-2.1,6-4.7,6s-4.7-2.7-4.7-6S9.3,1.9,11.9,1.9L11.9,1.9z"/>
						</g>
					</svg>
					Users
				</router-link>
			</li>
			<li class="logout">
				<a @click="logout()">
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="30px" height="22.3px" viewBox="0 0 30 22.3" style="overflow:visible;enable-background:new 0 0 30 22.3;" xml:space="preserve">
						<g>
							<path d="M4.7,12.5h15.8c0.8,0,1.4-0.6,1.4-1.4s-0.6-1.4-1.4-1.4H4.7L6.4,8C7,7.5,7,6.6,6.4,6.1C5.9,5.6,5,5.6,4.5,6.1l-4.1,4.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0C0,11,0,11,0,11c0,0,0,0.1,0,0.1s0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1l4.1,4.1c0.3,0.3,0.6,0.4,1,0.4c0.3,0,0.7-0.1,1-0.4c0.5-0.5,0.5-1.4,0-1.9L4.7,12.5z"/>
							<path d="M18.8,0c-3.7,0-7.2,1.8-9.3,4.9C9.2,5.6,9.3,6.4,10,6.8c0.6,0.4,1.5,0.3,1.9-0.4c1.6-2.3,4.2-3.7,7-3.7c4.6,0,8.4,3.8,8.4,8.4s-3.8,8.4-8.4,8.4c-2.8,0-5.4-1.4-7-3.7c-0.4-0.6-1.3-0.8-1.9-0.4c-0.6,0.4-0.8,1.3-0.4,1.9c2.1,3.1,5.5,4.9,9.2,4.9c6.2,0,11.2-5,11.2-11.2S25,0,18.8,0z"/>
						</g>
					</svg>
					Logout
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	methods: {
		logout() {
			this.$store.dispatch('logout')
		}
	},
	computed: {
		...mapState(['userProfile']),
		websites() {
			if ( this.userProfile.group === 'admin' || this.userProfile.access.resources.includes('websites') ) {
				return true
			} else {
				return false
			}
		},
		pages() {
			if ( this.userProfile.group === 'admin' || this.userProfile.access.resources.includes('pages') ) {
				return true
			} else {
				return false
			}
		},
		forms() {
			if ( this.userProfile.group === 'admin' || this.userProfile.access.resources.includes('forms') ) {
				return true
			} else {
				return false
			}
		},
		users() {
			if ( this.userProfile.group === 'admin' ) {
				return true
			} else {
				return false
			}
		}
	}
}
</script>

<style lang="scss" scoped>

</style>